<template>
  <!--会议会诊管理-->
  <div class="consultationMeets">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="consultationMeets_header">
        <div>
          类型: &nbsp;
        <a-radio-group v-model="type" style="margin-right: 40px"  @change="searchOk">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="METTING">
            会议
          </a-radio-button>
          <a-radio-button value="CONAULTATION">
            咨询
          </a-radio-button>
        </a-radio-group>
          名称: &nbsp;
          <a-input placeholder="请输入" v-model="searchName" style="width: 180px;margin-right: 20px"  @keyup.enter.native="searchBtn()"  />
          状态: &nbsp;
          <a-select v-model="searchType" placeholder="请选择"  style="width:110px">
            <a-select-option  :value="0">
              未结束
            </a-select-option>
            <a-select-option  :value="1">
              已结束
            </a-select-option>
          </a-select>

          <a-button type="primary" @click="searchBtn" style="margin-left: 30px;margin-right: 10px">
            查询
          </a-button>
          <a-button @click="searchChange">
            重置
          </a-button>
        </div>

      </div>
      <div class="consultationMeets_table">
        <a-button @click="addBtn" type="primary" style="margin-bottom: 5px;">
          新增
        </a-button>
            <!-- 会议数据 -->
            <a-table style="margin-bottom: 5px" :scroll="{ x: 1500 }" :columns="columns"  :data-source="InfoTable" :pagination="false" rowKey="id">
              <span slot="titles" slot-scope="text,item">
                <a-tag :color="item.type === 'CONAULTATION' ? 'green' : 'blue'">{{ item.type === 'CONAULTATION' ? '咨询' : '会议' }}</a-tag>
                {{ item.title }}
              </span>
              <span slot="is_opne" slot-scope="text,item">
                <span style="display: flex;justify-content: center;align-items: center" v-if="item.isOpen === 0" ><span style="background-color: blue;width: 8px;height: 8px;border-radius: 8px"></span>&nbsp; 未结束</span>
                <span style="display: flex;justify-content: center;align-items: center" v-if="item.isOpen === 1" ><span style="background-color: #00CC66;width: 8px;height: 8px;border-radius: 8px"></span>&nbsp;已结束</span>
              </span>
              <span slot="meetId" slot-scope="text,item">
                {{ item.hasOwnProperty('liveValue') ? item.liveValue : '非云ICU平台会议' }}
              </span>
              <span slot="open" slot-scope="text,item">
                {{ item.isFullOpen === 1 ? '是' : '否' }}
              </span>
              <span slot="is_open" slot-scope="text,item">
                {{ item.isOpen === 1 ? '结束' : '未结束' }}
              </span>
              <span slot="liveType" slot-scope="text,item">
                <span>{{ item.liveType === 'ZOOM' ? 'ZOOM':item.liveType === 'TENCENT'?'腾讯会议': '其他' }} </span>
                <!-- 云ICU -->
              </span>
              <span slot="operate" slot-scope="text,item">
                                  <a style="margin-right: 5px"  v-if="item.isFullOpen === 0" @click="memberBtn(item, item.id)">人员</a>
                                  <a style="margin-right: 5px;color: #aba9a9"  v-if="item.isFullOpen === 1" >人员</a>
                                  <a style="margin-right: 5px"  v-if="item.isOpen === 0" @click="editBtn(item)">修改</a>
                                  <a style="margin-right: 5px"   @click="logBtn(item.id, item.isOpen)">{{ item.isOpen === 1 ? '查看' : '记录' }}</a>
                                  <a-popconfirm
                                      style="margin-right: 5px"
                                      v-if="item.isOpen === 1"
                                      title="确定开启?"
                                      ok-text="是"
                                      cancel-text="否"
                                      @confirm="openTitle(item.id)"
                                      @cancel="cancel"
                                  >
                                  <a>开启</a>
                                  </a-popconfirm>
                                   <a-popconfirm
                                       v-if="item.isOpen === 0"
                                       title="确定结束?"
                                       ok-text="是"
                                       cancel-text="否"
                                       @confirm="delTitle(item.id)"
                                       @cancel="cancel"
                                   >
                                  <a>结束</a>
                                  </a-popconfirm>
                                </span>

                              </a-table>


                              <div style="display: flex;justify-content: flex-end;">
                                <a-pagination v-model="pagination.current"
                                              :total="pagination.total"
                                              @change="pageChange"/>
                              </div>
                            </div>

                            <a-modal :footer="null"  :dialogStyle="dialogStyle"
                                     :mask="false" v-model="logFormShow" title="查看 " @ok="() => { this.logFormShow = false; this.logLookForm = {} }">
                              <div >
                                截图：
                                  <div v-for="img in logLookForm.cover">
                                    <img :src="img" alt="" style="width: 100%;height: auto">
                                  </div>

                              </div>
                              <div >
                                视频：
                                  <div v-for="video in logLookForm.video">
                                    <video :src="video" controls style="width: 100%;height: 200px"></video>
                                  </div>
                              </div>

                              <div>
                                总结：{{ logLookForm.content }}
                              </div>
                            </a-modal>

                            <a-drawer
                                title="新增"
                                :visible="addShow"
                                width="600"
                                @close="onaddClose"
                            >
                              <a-form-model ref="addItem" style="padding-bottom:50px" :rules="addForm.liveType == 'TENCENT' ? rulesTenXun:rulesZoom" :label-col="labelCol" :model="addForm"  :wrapper-col="wrapperCol">
                                <a-form-model-item  label="类型"    prop="type">
                                  <a-radio-group v-model="addForm.type" >
                                    <a-radio value='CONAULTATION'>
                                      咨询
                                    </a-radio>
                                    <a-radio value='METTING'>
                                      会议
                                    </a-radio>
                                  </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item  label="形式"   prop="liveType" >
                                  <!-- style="width: 100%;display: flex;align-items: center;" -->
                                  <a-radio-group v-model="addForm.liveType" @change="radioChange" >
                                    <a-radio value='ZOOM'>
                                      ZOOM <span>（支持官网&APP入会）</span>
                                    </a-radio>
                                    <a-radio value='TENCENT'>
                                      腾讯会议  <span>（支持官网&小程序入会）</span>
                                    </a-radio>
                                  </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item label="咨询会议ID" prop="liveValue">
                                  <a-select
                                    v-model="addForm.liveValue"
                                    placeholder="请选择"
                                    ref="selectVal"
                                    @change="onseleChange"
                                    @search="onseleSearch"
                                    :show-search="true"
                                    :allowClear="false"
                                    @blur="onBlur"
                                  >
                                    <a-select-option
                                        v-for="item in meetIds"
                                        :value="item.meetId"
                                        :key="item.meetId"
                                        >
                                        <a-tooltip placement="topLeft">
                                          <template slot="title">
                                            {{ item.meetId + "【" + item.remark + '】'  }}
                                          </template>
                                          {{ item.meetId + "【" + item.remark + '】'  }}
                                        </a-tooltip>
                                    </a-select-option>
                                  </a-select>

                                </a-form-model-item>
                                <a-form-model-item label="会议链接" prop="meetUrl">
                                  <a-input v-model="addForm.meetUrl" placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item label="会议密码" prop="meetPassword">
                                  <a-input v-model="addForm.meetPassword" placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item  label="标题"   prop="title" >
                                  <a-input v-model="addForm.title" auto-size placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item  label="显示时间"   prop="timeDisplay" >
                                  <a-input v-model="addForm.timeDisplay" auto-size placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item  label="实际时间" prop="time">
                                  <a-range-picker
                                      v-model="addForm.time"
                                      show-time
                                      style="width: 100%"
                                      format="YYYY-MM-DD HH:mm:ss"
                                      :placeholder="['开始时间', '结束时间']"
                                  />
                                </a-form-model-item>
                                <a-form-model-item label="全员开放" prop="isFullOpen">
                                  <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />
                                </a-form-model-item>
                                <a-form-model-item  label="负责人" prop="byId">
                                  <a-select v-model="addForm.byId" placeholder="请选择" style="width:100%">
                                    <a-select-option
                                        v-for="select_o in leaderOperateList"
                                        :key="select_o.id"
                                        :value="select_o.id">
                                      {{ select_o.userName }}
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                              </a-form-model>

                              <div
                                  :style="{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                  }"
                              >
                                <a-button style="marginRight: 8px" @click="() => { this.addShow = false; }">
                                  取消
                                </a-button>
                                <a-button type="primary" @click="addOk">
                                  确认
                                </a-button>
                              </div>
                            </a-drawer>


                            <a-drawer
                                title="修改"
                                :visible="editShow"
                                width="600"
                                class="editForm"
                                @close="() => { this.editShow = false , this.meetIds = [] }"
                            >
                              <a-form-model ref="editItem" style="padding-bottom:50px" :rules="editForm.liveType == 'TENCENT' ? rulesTenXun:rulesZoom" :label-col="labelCol" :model="editForm"  :wrapper-col="wrapperCol">
                                <a-form-model-item  label="类型"    prop="type">
                                  <a-radio-group v-model="editForm.type" >
                                    <a-radio value='CONAULTATION'>
                                      咨询
                                    </a-radio>
                                    <a-radio value='METTING'>
                                      会议
                                    </a-radio>
                                  </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item  label="形式"   prop="liveType" >
                                  <a-radio-group v-model="editForm.liveType" @change="radioChange2">
                                    <a-radio value='ZOOM'>
                                      ZOOM（支持官网&APP入会）
                                    </a-radio>
                                    <a-radio value='TENCENT'>
                                      腾讯会议（支持官网&小程序入会）
                                    </a-radio>
                                  </a-radio-group>
                                </a-form-model-item>
                                <a-form-model-item label="咨询会议ID" prop="liveValue">
                                  <a-select
                                      v-model="editForm.liveValue"
                                      placeholder="请选择"
                                      @change="onsele2Change"
                                      @search="onsele2Search"
                                      :show-search="true"
                                      :allowClear="false"
                                      @blur="on2Blur"
                                  >
                                    <a-select-option
                                        v-for="item in meetIds"
                                        :value="item.meetId"
                                        :key="item.meetId">
                                        <a-tooltip placement="topLeft">
                                          <template slot="title">
                                            {{ item.meetId + "【" + item.remark + '】'  }}
                                          </template>
                                          {{ item.meetId + "【" + item.remark + '】'  }}
                                        </a-tooltip>
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                                <a-form-model-item label="会议链接" prop="meetUrl">
                                  <a-input v-model="editForm.meetUrl" placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item label="会议密码" prop="meetPassword">
                                  <a-input v-model="editForm.meetPassword" placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item  label="标题"   prop="title" >
                                  <a-input v-model="editForm.title" auto-size placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item  label="显示时间"   prop="timeDisplay" >
                                  <a-input v-model="editForm.timeDisplay" auto-size placeholder="请输入" />
                                </a-form-model-item>
                                <a-form-model-item  label="实际时间" prop="beginTime">
                                  <div style="display: flex;align-items: center" >
                                    <a-date-picker style="min-width: 45%" show-time placeholder="开始时间" v-model="editForm.beginTime" />~
                                    <a-date-picker style="min-width: 45%" show-time placeholder="结束时间" v-model="editForm.endTime" />
                                  </div>
                                </a-form-model-item>
                                <a-form-model-item label="全员开放" prop="isFullOpen">
                                  <a-switch :checked="editForm.isFullOpen !== 0"   @click="openEdit(editForm.isFullOpen === 1 ? 0 : 1)"
                                            checked-children="是" un-checked-children="否"  />
                                </a-form-model-item>
                                <a-form-model-item  label="负责人" prop="createdById">
                                  <a-select v-model="editForm.createdById" placeholder="请选择" style="width:100%">
                                    <a-select-option
                                        v-for="select_o in leaderOperateList"
                                        :key="select_o.id"
                                        :value="select_o.id">
                                      {{ select_o.userName }}
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                              </a-form-model>

                              <div
                                  :style="{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                  }"
                              >
                                <a-button style="marginRight: 8px" @click="() => { this.editShow = false; }">
                                  取消
                                </a-button>
                                <a-button type="primary" @click="editOk">
                                  确认
                                </a-button>
                              </div>
                            </a-drawer>
                                            <!-- 注册注册人员名单-->
                                                  <a-drawer :width="960" :visible="ExcelMember" title="参会人员" :body-style="{ paddingBottom: '80px' }"
                                                    @close="excelClose">
                                                    <div style="width: 100% ;">
                                                      <!-- 查询人员 -->
                                                      <h3 style="width: 100% ;margin-top: 1%;margin-bottom: 2%; font-weight: 700;">查询人员</h3>
                                                      <!-- 注册人员数据 -->
                                                      <a-spin :spinning="spinning">
                                                        <div class="registerMember">
                                                          <div class="registerMember-right">
                                                            <div class="registerMember-right-top">
                                                              <!--   顶部搜索  -->
                                                              <div style="display: flex;align-items: center;margin-left: 43px">
                                                                <div class="registerMember-right-top-input">
                                                                  姓名: <a-input v-model="searchForm.name" style="width:78%" id="input" @pressEnter="enterBtn" placeholder="请输入" />
                                                                </div>
                                                                <div class="registerMember-right-top-input">
                                                                  手机号: <a-input v-model="searchForm.number" style="width: 78%" @pressEnter="enterBtn"
                                                                    placeholder="请输入" />
                                                                </div>
                                                                <div  style="display:flex;justify-content:space-between;width: 20%; margin-left: 8%;">
                                                                            <a-button @click="selectBtn">查询</a-button>
                                                                            <!-- 新增按钮 -->
                                                                            <a-button type="primary"  @click="addPerson">新增</a-button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                <div class="registerMember-right-table">
                                                                  <!--   列表   -->
                                                                  <div class="registerMember-right-table-top-table">
                                                                    <!-- <a-table style="margin-top: 8px" :columns="columnss" :data-source="[]" /> -->
                                                                    <a-table  style="width:100%" :columns="columnstwo" :pagination="false"
                                                                      :rowKey='record => record.memberId' :data-source="tableList"  id="try">
                                                                      <span slot="operation" slot-scope="text,record">
                                                                      <a-popconfirm cancel-text="否" ok-text="是" title="是否确认添加?" @confirm="amendBtnTable(record.memberId)">
                                                                       <a style="margin-left: 8px" id="Selected" v-if="record.status === 0">选择</a>
                                                                       <!-- v-if="1" -->
                                                                      </a-popconfirm>
                                                                      <a style="margin-left: 8px;color:red" id="Selected" v-if="record.status === 1">已选择</a>
                                                                      </span>
                                                                    </a-table>
                                                                  </div>
                                                                        <div style="width: 100%">
                                                                          <div class="pageClass">
                                                                            <a-pagination
                                                                                :current="pageNo"
                                                                                @change="page1Change"
                                                                                :total="total"
                                                                                show-quick-jumper
                                                                                :pageSize="listpageSize"
                                                                                 />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </a-spin>
                                                                <!--上传文件  -->
                                                                <h3 style="margin-top: 4%;margin-bottom: 3%; font-weight: 700;">人员导入</h3>
                                                                <div style="display: flex;justify-content: center;height: 35px;line-height: 35px">
                                                                  <span>选择文件</span>
                                                                  <a-input-search readOnly v-model="changeFile" style="width: 260px;margin-left: 5px" placeholder="请上传.xlsx文件">
                                                                    <a-button slot="enterButton" style="width: 80px">
                                                                      <div class="add-file">
                                                                        <!--                    <div style="margin-top: 5px;margin-left: -25px"  @click="toImport">选择</div>-->
                                                                        <div>选择</div>
                                                                        <input id="uploadFile" type="file" @change="changeFileElsx" ref="uploadFile_inputFile" class="fileAdd"
                                                                          style="position: absolute;top: 1px;left: 1px;width: 80px;opacity:0" accept=".xlsx,.xls" />
                                                                      </div>
                                                                    </a-button>
                                                                  </a-input-search>
                                                                  <a-button @click="importAddFile" style="margin-left: 10px" type="primary">确认导入</a-button>
                                                                  <a-button @click="DownloadTemplate" style="margin-left: 10px">下载模板</a-button>
                                                                </div>
                                                                <h3 style="margin-top: 7%;margin-bottom: 2%; font-weight: 700;">参会人员</h3>
                                                                <div style="display: flex;height: 35px;line-height: 35px;margin-top: 20px;justify-content: space-between">
                                                                          <!-- 查询参会人员按钮 标记 -->
                                                                          <div style="margin-left:24px">
                                                                            <span style="margin-left: 20px">姓名：</span>
                                                                            <a-input allowClear placeholder="请输入" style="width: 200px; margin-right:15px" @pressEnter="inquireBtn" v-model="listName" />
                                                                            <span>手机号：</span>
                                                                            <a-input allowClear placeholder="请输入" style="width: 200px;" @pressEnter="inquireBtn" v-model="listTel" />
                                                                            <!-- 查询按钮 -->
                                                                          </div>
                                                                          <!-- 新增按钮 -->
                                                                          <div style="width:244px;display: flex;justify-content: space-around;">
                                                                            <a-button @click="inquireBtn" style="margin-left: 10px" type="primary">查询</a-button>
                                                                                                      <a-button   @click="Export" > <a-icon type="file-excel" style="color:green" /> 导出</a-button>
                                                                                                </div>
                                                                                              </div>
                                                                                              <!-- 查出来的参会人员数据 -->
                                                                                              <div style="margin-top: 20px">
                                                                                                <a-table :columns="columnsList" :pagination="false" :rowKey='record => record.id' :data-source="dataLists">
                                                                                                  <template slot="action" slot-scope="text, record">
                                                                                                    <a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="confirmDelList(record.id)"><a>删除</a>
                                                                                                    </a-popconfirm>
                                                                                                  </template>
                                                                                                  <template slot="serialNumber" slot-scope="text, record,index">
                                                                                                    <span>{{ (listPageNo - 1) * 10 + index + 1 }}</span>
                                                                                                  </template>
                                                                                                  <template slot="type" slot-scope="text, record">
                                                                                                    <span v-if="text === '1001'">医生</span>
                                                                                                    <span v-if="text === '1002'">护士</span>
                                                                                                    <span v-if="text === '1003'">医技</span>
                                                                                                    <span v-if="text === '1004'">药师</span>
                                                                                                    <span v-if="text === '1005'">学生</span>
                                                                                                    <span v-if="text === '1006'">其他医务从业者</span>
                                                                                                    <span v-if="text === '1007'">非医务从业者</span>
                                                                                                  </template>
                                                                                                </a-table>
                                                                                                <div style="width: 100%;display: flex;justify-content: right;margin-top: 5px">
                                                                                                  <a-pagination show-quick-jumper show-size-changer :current="listPageNo" :pageSize="listpageSize"
                                                                                                    :total="listCount" @showSizeChange="templateChangeSize" @change="templateChange" />
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </a-drawer>
                                                                                        <!-- 人员名单新增-->
                                                                                        <a-drawer
                                                                                            :width="500"
                                                                                            :visible="addPersonVisile"
                                                                                            title="人员名单新增"
                                                                                            :body-style="{ paddingBottom: '80px' }"
                                                                                            @close="addPersonClose">
                                                                                          <a-form-model
                                                                                              ref="PersonnelListRef"
                                                                                              :model="personnelListForm"
                                                                                              :rules="personnelListRules"
                                                                                              :label-col="labelCol"
                                                                                              :wrapper-col="wrapperCol"
                                                                                          >
                                                                                            <a-form-model-item label="手机号" prop="tel">
                                                                                              <a-input-search
                                                                                                  v-model="personnelListForm.tel"
                                                                                                  style="width: 350px"
                                                                                                  placeholder="请输入"
                                                                                                  enter-button="搜索"
                                                                                                  size="large"
                                                                                                  @search="onSearch"
                                                                                              />
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="身份">
                                                                                              <a-select
                                                                                                  :disabled="addPersonDisabled"
                                                                                                  show-search
                                                                                                  option-filter-prop="children"
                                                                                                  @change="selectNumberType"
                                                                                                  placeholder="请选择"
                                                                                                  v-model="personnelListForm.type"
                                                                                                  style="width: 300px">
                                                                                                <a-select-option value="1001">
                                                                                                  医生
                                                                                                </a-select-option>
                                                                                                <a-select-option value="1002">
                                                                                                  护士
                                                                                                </a-select-option>
                                                                                                <a-select-option value="1003">
                                                                                                  医技
                                                                                                </a-select-option>
                                                                                                <a-select-option value="1004">
                                                                                                  药师
                                                                                                </a-select-option>
                                                                                                <a-select-option value="1005">
                                                                                                  学生
                                                                                                </a-select-option>
                                                                                                <a-select-option value="1006">
                                                                                                  其他医务从业者
                                                                                                </a-select-option>
                                                                                                <a-select-option value="1007">
                                                                                                  非医务从业者
                                                                                                </a-select-option>
                                                                                              </a-select>
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="姓名" prop="name">
                                                                                              <a-input id="name" @change="nameChange" :disabled="addPersonDisabled" style="width: 300px;" v-model="personnelListForm.name" placeholder="请输入" />
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="省份/城市">
                                                                                              <a-cascader
                                                                                                  :disabled="addPersonDisabled"
                                                                                                  style="width: 300px"
                                                                                                  v-model="personnelListForm.province"
                                                                                                  :options="provincePtions"
                                                                                                  placeholder="请选择"
                                                                                                  @change="provinceChange"
                                                                                              />
                                                                                            </a-form-model-item>
                                                                                            <!-------------------------------1001 ~ 1004---------------------------------->
                                                                                            <div v-if="personnelListForm.type === '1001' || personnelListForm.type === '1002' || personnelListForm.type === '1003' || personnelListForm.type === '1004'">
                                                                                              <a-form-model-item label="医院">
                                                                                                <a-select
                                                                                                    show-search
                                                                                                    option-filter-prop="children"
                                                                                                    @popupScroll="popupScrollHos"
                                                                                                    @search="selectHos"
                                                                                                    :disabled="addPersonDisabled"
                                                                                                    style="width: 300px"
                                                                                                    v-model="personnelListForm.hospitalName"
                                                                                                    placeholder="请选择">
                                                                                                  <a-select-option
                                                                                                      v-for="item in hospitalNameList"
                                                                                                      :value="item.name">
                                                                                                    {{ item.name }}
                                                                                                  </a-select-option>
                                                                                                </a-select>
                                                                                              </a-form-model-item>
                                                                                              <a-form-model-item label="科室">
                                                                                                <a-cascader
                                                                                                    :disabled="addPersonDisabled"
                                                                                                    style="width: 300px"
                                                                                                    v-model="personnelListForm.hospitalOffices"
                                                                                                    :options="hospitalOfficesList"
                                                                                                    placeholder="请选择"/>
                                                                                              </a-form-model-item>
                                                                                              <a-form-model-item label="职称">
                                                                                                <a-select
                                                                                                    :disabled="addPersonDisabled"
                                                                                                    style="width: 300px"
                                                                                                    v-model="personnelListForm.jobTitle"
                                                                                                    placeholder="请选择">
                                                                                                  <a-select-option
                                                                                                      v-for="item in jobTitleList"
                                                                                                      :value="item.jobTitle">
                                                                                                    {{ item.jobTitle }}
                                                                                                  </a-select-option>
                                                                                                </a-select>
                                                                                              </a-form-model-item>
                                                                                            </div>
                                                                                            <!-------------------------------1005------------------------------------------->
                                                                                            <div v-if="personnelListForm.type === '1005'">
                                                                                              <a-form-model-item label="学院" >
                                                                                                <a-select
                                                                                                    show-search
                                                                                                    option-filter-prop="children"
                                                                                                    @popupScroll="popupScrollSchool"
                                                                                                    @search="selectSchool"
                                                                                                    :disabled="addPersonDisabled"
                                                                                                    style="width: 300px"
                                                                                                    v-model="personnelListForm.school"
                                                                                                    placeholder="请选择">
                                                                                                  <a-select-option
                                                                                                      v-for="item in schoolListData"
                                                                                                      :value="item.name">
                                                                                                    {{ item.name }}
                                                                                                  </a-select-option>
                                                                                                </a-select>
                                                                                              </a-form-model-item>
                                                                                              <a-form-model-item label="学历">
                                                                                                <a-select :disabled="addPersonDisabled" style="width: 300px" placeholder="请选择" v-model="personnelListForm.education">
                                                                                                  <a-select-option
                                                                                                      v-for="item in educationList"
                                                                                                      :value="item.education">
                                                                                                    {{ item.education }}
                                                                                                  </a-select-option>
                                                                                                </a-select>
                                                                                              </a-form-model-item>
                                                                                              <a-form-model-item label="专业">
                                                                                                <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.specialty" placeholder="请输入" />
                                                                                              </a-form-model-item>
                                                                                            </div>
                                                                                            <!-------------------------------1006  ~  1007------------------------------------------->
                                                                                            <div v-if="personnelListForm.type === '1006' || personnelListForm.type === '1007'">
                                                                                              <a-form-model-item label="单位" >
                                                                                                <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.workUnit" placeholder="请输入" />
                                                                                              </a-form-model-item>
                                                                                              <a-form-model-item label="职务" >
                                                                                                <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.post" placeholder="请输入" />
                                                                                              </a-form-model-item>
                                                                                            </div>
                                                                                            <!--      新增时共有的        -->
                                                                                            <a-form-model-item label="性别">
                                                                                              <a-select :disabled="addPersonDisabled" style="width: 300px" placeholder="请选择" v-model="personnelListForm.sex">
                                                                                                <a-select-option
                                                                                                    v-for="item in sexList"
                                                                                                    :value="item.value">
                                                                                                  {{ item.sex }}
                                                                                                </a-select-option>
                                                                                              </a-select>
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="邮箱">
                                                                                              <a-input :disabled="addPersonDisabled" style="width: 300px" v-model="personnelListForm.email" placeholder="请输入" />
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="生日">
                                                                                              <a-date-picker :disabled="addPersonDisabled" placeholder="请选择" style="width: 300px" v-model="personnelListForm.birthday" />
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="认证状态">
                                                                                              <!--            <a-input :disabled="true" style="width: 300px" v-model="personnelListForm.certStatus" placeholder="请输入" />-->
                                                                                              <a-select
                                                                                                  :disabled="true"
                                                                                                  style="width: 300px"
                                                                                                  v-model="personnelListForm.certStatus"
                                                                                                  placeholder="请选择">
                                                                                                <a-select-option
                                                                                                    v-for="item in certStatus"
                                                                                                    :value="item.value">
                                                                                                  {{ item.name }}
                                                                                                </a-select-option>
                                                                                              </a-select>
                                                                                            </a-form-model-item>
                                                                                            <a-form-model-item label="注册渠道">
                                                                                              <a-input :disabled="true" style="width: 300px;margin-bottom: 20px" v-model="personnelListForm.registerChannel" placeholder="请输入" />
                                                                                            </a-form-model-item>
                                                                                          </a-form-model>
                                                                                          <div style="display: flex;margin-left: 320px">
                                                                                            <a-button v-show="addPersonDisabled" @click="editPerBtn" type="primary">编辑</a-button>
                                                                                            <div style="display: flex" v-show="!addPersonDisabled">
                                                                                              <a-button @click="() => { this.addPersonDisabled = true }">取消</a-button>
                                                                                              <a-button @click="saveEditPer" type="primary" style="margin-left: 10px">保存</a-button>
                                                                                            </div>
                                                                                          </div>
                                                                                          <div
                                                                                              :style="{
                                                                                                position: 'absolute',
                                                                                                right: 0,
                                                                                                bottom: 0,
                                                                                                width: '100%',
                                                                                                borderTop: '1px solid #e9e9e9',
                                                                                                padding: '10px 16px',
                                                                                                background: '#fff',
                                                                                                textAlign: 'right',
                                                                                                zIndex: 1,
                                                                                              }"
                                                                                          >
                                                                                            <a-button :style="{ marginRight: '1px' }" @click="addPersonClose">取消</a-button>
                                                                                            <a-button type="primary" @click="addPersonOnSubmit" :disabled = this.btnStatus >确认</a-button>
                                                                                          </div>
                                                                                        </a-drawer>
                                                                                                <!--      <a-drawer-->
                                                                                                <!--          title="参会人员"-->
                                                                                                <!--          width="900"-->
                                                                                                <!--          :visible="scopeShow"-->
                                                                                                <!--          @close="()=>{this.scopeShow = false;this.telData=[];this.meetData=[];this.memberTel='';this.memberName='';this.memberLogName='';this.memberLogTel=''}"-->
                                                                                                <!--      >-->
                                                                                                <!--        <div class="selectPersonnel" >-->
                                                                                                <!--          <h3>查询人员</h3>-->
                                                                                                <!--          <div style="display: flex;align-items: center;width: 60%;justify-content: space-between;margin: 5px 0 5px 0">-->
                                                                                                <!--            <div> 姓名: &nbsp;<a-input v-model="memberName" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchTel()"/></div>-->
                                                                                                <!--            <div> 手机号: &nbsp;<a-input v-model="memberTel" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchTel()"/></div>-->
                                                                                                <!--            <a-button type="primary"  @click="searchTel"> 查询</a-button>-->
                                                                                                <!--          </div>-->
                                                                                                <!--          <a-table :columns="memberColumn2" :data-source="telData" rowKey="memberId"   :pagination="false"-->
                                                                                                <!--          >-->
                                                                                                <!--            <span slot="operate" slot-scope="text,item">-->
                                                                                                <!--              <a @click="addPeople(item.id)">选择</a>-->
                                                                                                <!--            </span>-->
                                                                                                <!--          </a-table>-->
                                                                                                <!--        </div>-->
                                                                                                <!--        <div class="selectedStaff" style="margin: 10px 0 10px;">-->
                                                                                                <!--          <div style="display: flex;justify-content: space-between">-->
                                                                                                <!--            <h3>参会人员</h3>-->
                                                                                                <!--          </div>-->
                                                                                                <!--          <div style="display: flex;align-items: center;width: 60%;justify-content: space-between;margin: 5px 0 5px 0">-->
                                                                                                <!--            <div> 姓名: &nbsp;<a-input v-model="memberLogName" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchLogTel()"/></div>-->
                                                                                                <!--            <div> 手机号: &nbsp;<a-input v-model="memberLogTel" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchLogTel()"/></div>-->
                                                                                                <!--            <a-button type="primary"  @click="searchLogTel"> 查询</a-button>-->
                                                                                                <!--          </div>-->
                                                                                                <!--          <a-table :columns="memberColumn" :data-source="memberData" :rowKey="(record,index)=>{return index}"   :pagination="false"-->
                                                                                                <!--          >-->
                                                                                                <!--            <span slot="operate" slot-scope="text,item">-->
                                                                                                <!--              <a-popconfirm-->
                                                                                                <!--                  title="确定移除?"-->
                                                                                                <!--                  ok-text="是"-->
                                                                                                <!--                  cancel-text="否"-->
                                                                                                <!--                  @confirm="delMember(item.id)"-->
                                                                                                <!--                  @cancel="cancel"-->
                                                                                                <!--              >-->
                                                                                                <!--            <a>移除</a>-->
                                                                                                <!--            </a-popconfirm>-->
                                                                                                <!--            </span>-->
                                                                                                <!--          </a-table>-->
                                                                                                <!--        </div>-->
                                                                                                <!--        <div style="margin-bottom:40px;align-items: center;display: flex;justify-content: space-between">-->
                                                                                                <!--          <span style="font-size: small;color: #929292">共 {{memberPage.total}} 条记录 第 {{ memberPage.current }} / {{ Math.ceil(memberPage.total / memberPage.pageSize) }} 页</span>-->
                                                                                                <!--          <a-pagination-->
                                                                                                <!--              size="small"-->
                                                                                                <!--              :page-size-options="memberPage.sizeOptions"-->
                                                                                                <!--              v-model="memberPage.current"-->
                                                                                                <!--              :total="memberPage.total"-->
                                                                                                <!--              show-size-changer-->
                                                                                                <!--              :page-size="memberPage.pageSize"-->
                                                                                                <!--              @change="memberPageChange"-->
                                                                                                <!--              @showSizeChange="memberPageChange"-->
                                                                                                <!--              show-quick-jumper-->
                                                                                                <!--              style="float: right"-->
                                                                                                <!--          >-->
                                                                                                <!--          </a-pagination>-->
                                                                                                <!--        </div>-->
                                                                                                <!--        <div-->
                                                                                                <!--            :style="{-->
                                                                                                <!--          position: 'absolute',-->
                                                                                                <!--          bottom: 0,-->
                                                                                                <!--          width: '100%',-->
                                                                                                <!--          borderTop: '1px solid #e8e8e8',-->
                                                                                                <!--          padding: '10px 16px',-->
                                                                                                <!--          textAlign: 'right',-->
                                                                                                <!--          left: 0,-->
                                                                                                <!--          background: '#fff',-->
                                                                                                <!--          borderRadius: '0 0 4px 4px',-->
                                                                                                <!--        }"-->
                                                                                                <!--        >-->
                                                                                                <!--          <a-button style="marginRight: 8px" @click="()=>{this.scopeShow = false;this.telData=[];this.meetData=[];this.memberTel='';this.memberName='';this.memberLogName='';this.memberLogTel=''}">-->
                                                                                                <!--            取消-->
                                                                                                <!--          </a-button>-->
                                                                                                <!--          <a-button type="primary"-->
                                                                                                <!--                    @click="()=>{this.scopeShow = false;this.telData=[];this.meetData=[];this.memberTel='';this.memberName='';this.memberLogName='';this.memberLogTel=''}">-->
                                                                                                <!--            确认-->
                                                                                                <!--          </a-button>-->
                                                                                                <!--        </div>-->
                                                                                                <!--      </a-drawer>-->
                                                                                                      <a-drawer
                                                                                                          title="记录"
                                                                                                          width="700"
                                                                                                          :visible="logShow"
                                                                                                          @close="() => { this.logShow = false; this.logForm = { cover: [], video: [] }; this.logAddType = 0 }"
                                                                                                      >
                                                                                                        <div class="selectPersonnel"  v-if="logType === 0">
                                                                                                          <h3>添加记录</h3>
                                                                                                          <a-form-model style="margin-left: -70px" ref="logItem"  :label-col="labelCol" :model="logForm"  :wrapper-col="wrapperCol">
                                                                                                            <a-form-model-item  label="截图" >
                                                                                                              <div class="uploadImg">
                                                                                                                <input
                                                                                                                    id="addImg"
                                                                                                                    ref="addImg"
                                                                                                                    accept="image/*"
                                                                                                                    style="width: 100%;height:100%;opacity: 0;"
                                                                                                                    type="file"
                                                                                                                    @change="uploadImg"/>
                                                                                                              </div>
                                                                                                              <div style="margin-left: 110px;display: flex;flex-wrap: wrap">
                                                                                                                <div
                                                                                                                    v-for="(img, index) in logForm.cover"
                                                                                                                    style="position: relative;margin-right: 8px;margin-bottom: 8px">
                                                                                                                  <div class="upload_gutter">
                                                                                                                    <a-icon
                                                                                                                        style="color: red;font-size: 20px;position:absolute;top: 5px;right: 5px"
                                                                                                                        type="close-circle"
                                                                                                                        @click="delImg(index)"/>
                                                                                                                    <img
                                                                                                                        v-if="img"
                                                                                                                        :src="img"
                                                                                                                        alt=""
                                                                                                                        style="width: 100%;height:100%"/>
                                                                                                                  </div>
                                                                                                                </div>
                                                                                                              </div>
                                                                                                            </a-form-model-item>

                                                                                                            <a-form-model-item  label="视频" >
                                                                                                              <div class="uploadImg">
                                                                                                                <input
                                                                                                                    id="addVideo"
                                                                                                                    ref="addVideo"
                                                                                                                    accept="video/mp4"
                                                                                                                    style="width: 100%;height:100%;opacity: 0;"
                                                                                                                    type="file"
                                                                                                                    @change="uploadVideo($event)"/>
                                                                                                              </div>
                                                                                                                <div v-for="(video, index) in logForm.video" style="margin-left: 110px">
                                                                                                                  <div class="upload_video"  >
                                                                                                                    <a-icon
                                                                                                                        style="color: red;font-size: 20px;position:absolute;right: 2px;z-index: 4"
                                                                                                                        type="close-circle"
                                                                                                                        @click="delVideo(index)"/>
                                                                                                                    <video
                                                                                                                        controls
                                                                                                                        v-if="video"
                                                                                                                        :src="video"
                                                                                                                        style="width: 100%;height:100%"></video>
                                                                                                                  </div>
                                                                                                                </div>
                                                                                                            </a-form-model-item>
                                                                                                            <a-form-model-item label="总结">
                                                                                                              <a-textarea
                                                                                                                  v-model="logForm.content"
                                                                                                                  placeholder="请输入"
                                                                                                                  :auto-size="{ minRows: 3, maxRows: 5 }"
                                                                                                              />
                                                                                                            </a-form-model-item>

                                                                                                          </a-form-model>
                                                                                                          <div style="display: flex;justify-content: center">
                                                                                                            <a-button v-if="logAddType === 0" type="primary" @click="logAddOk">提交</a-button>
                                                                                                            <a-button v-if="logAddType === 1" type="primary" @click="logEditOk">修改</a-button>
                                                                                                          </div>


                                                                                                        </div>


                                                                                                        <div class="selectedStaff" style="margin: 10px 0 40px;">
                                                                                                          <div style="display: flex;justify-content: space-between">
                                                                                                            <h3>记录</h3>
                                                                                                          </div>
                                                                                                          <a-table :columns="logColumn" :data-source="logData" :rowKey="(record, index) => { return index }"   :pagination="false"
                                                                                                          >
                                                                                                            <span slot="link" slot-scope="text,item">
                                                                                                              <a @click="logLook(item)">查看</a>

                                                                                                            </span>
                                                                                                            <span slot="operate" slot-scope="text,item">
                                                                                                              <a @click="logEditBtn(item)">编辑</a>&nbsp;&nbsp;&nbsp;
                                                                                                              <a-popconfirm
                                                                                                                  title="确定删除?"
                                                                                                                  ok-text="是"
                                                                                                                  cancel-text="否"
                                                                                                                  @confirm="delLog(item.id)"
                                                                                                                  @cancel="cancel"
                                                                                                              >
                                                                                                            <a>删除</a>
                                                                                                            </a-popconfirm>
                                                                                                            </span>
                                                                                                          </a-table>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            :style="{
                                                                                                              zIndex: 5,
                                                                                                              position: 'absolute',
                                                                                                              bottom: 0,
                                                                                                              width: '100%',
                                                                                                              borderTop: '1px solid #e8e8e8',
                                                                                                              padding: '10px 16px',
                                                                                                              textAlign: 'right',
                                                                                                              left: 0,
                                                                                                              background: '#fff',
                                                                                                              borderRadius: '0 0 4px 4px',
                                                                                                            }"
                                                                                                        >
                                                                                                          <a-button style="marginRight: 8px" @click="() => { this.logShow = false; this.logForm = { cover: [], video: [] }; this.logAddType = 0 }">
                                                                                                            取消
                                                                                                          </a-button>
                                                                                                          <a-button type="primary" @click="() => { this.logShow = false; this.logForm = { cover: [], video: [] }; this.logAddType = 0 }">
                                                                                                            确认
                                                                                                          </a-button>
                                                                                                        </div>
                                                                                                      </a-drawer>
                                                                                                    </a-spin>
                                                                                                  </div>


</template>

<script>
import {
  delConsultationMeet,
  delMeetLog,
  delMeetMember, delMeetPer,
  get_staff_list,
  getConsultationMeet,
  getMeetLog,
  getMeetMember,
  getMeetMemberTel,
  getZoomMeet,
  getZoomMeet2, insMeetMember,
  meetMemberTemplate,
  memberByTel, openMeetLog,
  postConsultationMeet,
  postMeetLog,
  postMeetMember,
  postUploadFileMeet,
  putConsultationMeet,
  putMeetLog,
  getMembersList
} from "@/service/zoomMeet_api";

import xlsx from 'xlsx';
import { getCommunictionList } from "@/service/liveMeet_api";
import moment from "moment";
import { update } from "@/utils/update";
import { get_signature, get_videoLink, getMemberList, postMember, putMember, PostRegisterMember, PostExcel } from "@/service/MedicalConference_y";
import { educationList, sexDate } from "@/utils/dateReturn";
import City from "@/assets/city.json";
import { getTitle } from "@/service/dataDictionary_api";
import { getHospital } from "@/service/hospital_api";
import { getSchool } from "@/service/school_api";
import { getLoginDepartment } from "@/service/dataCleaned_api";

import { delAdminList, restPassWorld } from "@/service/recruitment";
import ListPackaging from '@/views/content/case/ListPackaging'
import { admin_icon } from "@/utils/myIcon_f";

export default {
  components: {
    ListPackaging,
    admin_con: admin_icon,
  },
  QueriedData: [],
  attendData: [],
  name: "appointmentLog",
  data() {
    return {
      btnStatus:false,
      zhuangtai:false,
      // cmovies:false,
      dataSum: [],
      QueriedDataSum: [],
      Excel: {
        name: "韩",
        tel: "131",
        consultationId: null,
      },
      registerMembers: {
        memberId: '',
        meetId: ''
      },
      City: City,
      PCHList: [],
      selectId: ['全部会员'],
      //顶部搜索
      searchForm: {
        number: '',
        name: '',
        certStatus: '',
      },
      columnss: [
         {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
          key: 'tel',
          align: 'center',
        },
        {
          title: '医院',
          dataIndex: 'hospital',
          align: 'hospital',
          key: 'tel2',
          // scopedSlots: { customRender: 'hospitalName' },
        },
        {
          title: '科室',
          dataIndex: 'department',
          key: 'department',
          ellipsis: true,
          align: 'center',
          // scopedSlots: { customRender: 'hospitalOffices' },
        },
        {
          title: '操作',
          dataIndex: 'status',
          key: 'tel3',
          // fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      columnstwo: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '手机号码',
          dataIndex: 'tel',
          key: 'tel',
          align: 'center',
        },
        {
          title: '医院',
          dataIndex: 'hospital',
          align: 'hospital',
          key: 'tel2',
          // scopedSlots: { customRender: 'hospitalName' },
        },
        {
          title: '科室',
          dataIndex: 'department',
          key: 'department',
          ellipsis: true,
          align: 'center',
          // scopedSlots: { customRender: 'hospitalOffices' },
        },
        {
          title: '操作',
          dataIndex: 'status',
          key: 'tel3',
          // fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableList: [],
      //总数
      total: 2,
      pageNo: 1,
      spinning: false,
      addVisible: false,
      selectedRowKeys: [],
      user_id: [],
      labelCol: { span:  6},
      label2Col: { span:  4},
      wrapperCol: { span: 14 },
      wrapper2Col: { span: 19 },
      addForm: {
        type: '1001',
        name: '',
        tel: '',
        province: undefined,
        city: '',
        hospitalName: undefined,
        hospitalOffices: undefined,
        jobTitle: undefined,
        school: undefined,
        education: undefined,
        specialty: '',
        workUnit: '',
        post: '',
        sex: undefined,
        email: '',
        birthday: '',
        certStatus: '未认证',
        registerChannel: '后台添加',
        meetUrl:"",
        meetPassword:"",
      },
      rules: {},
      rulesTenXun:{
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        meetUrl: [{ required: true, message: '请输入', trigger: 'change' }],
        liveValue: [{ required: true, message: '请输入', trigger: 'blur' }],
        time: [{ required: true, message: '请输入', trigger: 'change' }],
        timeDisplay: [{ required: true, message: '请输入', trigger: 'change' }],
        title: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      rulesZoom:{
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        // meetUrl: [{ required: true, message: '请输入', trigger: 'change' }],
        liveValue: [{ required: true, message: '请输入', trigger: 'blur' }],
        time: [{ required: true, message: '请输入', trigger: 'change' }],
        timeDisplay: [{ required: true, message: '请输入', trigger: 'change' }],
        title: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      //1001-1004
      rules1: {
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        hospitalName: [{ required: true, message: '请选择', trigger: 'change' }],
        hospitalOffices: [{ required: true, message: '请选择', trigger: 'change' }],
        jobTitle: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      //1005
      rules2: {
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        school: [{ required: true, message: '请选择', trigger: 'change' }],
        education: [{ required: true, message: '请选择', trigger: 'change' }],
        specialty: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      //1006-1007
      rules3: {
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        workUnit: [{ required: true, message: '请输入', trigger: 'change' }],
        post: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      hospitaloPtions: [],
      schoolPtions: [],
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          isLeaf: false,
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          isLeaf: false,
        },
      ],
      hospitalNameList: [],
      hospitalNameList_pageNo: 1,
      hospitalOfficesList: [],
      jobTitleList: [],
      schoolListData: [],
      educationList: educationList(),
      sexList: sexDate(),
      drawerTitle: '',
      reg: RegExp(/-/),
      spinning: false,
      meetShow: false,
      scopeShow: false,
      logShow: false,
      logFormShow: false,
      type: '',
      memberName: '',
      memberTel: '',
      memberLogTel: '',
      memberLogName: '',
      dialogStyle: {
        top: '20px',
        left: '20px',
        float: 'left',
      },
      logLookForm: {},
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        timeDisplay: [{ required: true, message: '请输入显示时间', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'change' }],
        beginTime: [{ required: true, message: '请选择时间', trigger: 'change' }],
        byId: [{ required: true, message: '请选择负责人', trigger: 'change' }],
        createdById: [{ required: true, message: '请选择负责人', trigger: 'change' }],
        isFullOpen: [{ required: true, message: '是否全员开放', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        liveType: [{ required: true, message: '请选择形式', trigger: 'blur' }],
      },
      addForm: {
        byId: null,
        isFullOpen: 0,
        meetUrl:""
      },
      editForm: {},
      searchID: null,
      searchName: '',
      searchType: 0,
      meetID: null,
      addShow: false,
      editShow: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      memberPage: {
        sizeOptions: ['10', '30', '50'],
        total: 0,
        current: 1,
        pageSize: 10,
      },
      leaderOperateList: [],
      InfoTable: [],
      meetData: [],
      memberData: [],
      telData: [],
      logData: [],

      columns: [
        {
          title: '名称',
          scopedSlots: { customRender: 'titles' },
        },
        {
          title: '显示时间',
          align: 'center',
          dataIndex: 'timeDisplay',
        },
        {
          title: '会议ID',
          align: 'center',
          scopedSlots: { customRender: 'meetId' },
        },
        {
          title: '负责人',
          width: '8%',
          align: 'center',
          dataIndex: 'createdByName',
        },
        {
          title: '状态',
          align: 'center',
          width: '8%',
          scopedSlots: { customRender: 'is_open' },
        },
        {
          title: '实际开始',
          align: 'center',
          dataIndex: 'beginTime',
        },
        {
          title: '实际结束',
          align: 'center',
          dataIndex: 'endTime',
        },

        {
          title: '全员开放',
          width: '8%',
          align: 'center',
          scopedSlots: { customRender: 'open' },
        },
        {
          title: '形式',
          align: 'center',
          width: '6%',
          scopedSlots: { customRender: 'liveType' },
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      memberColumn: [
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机',
          dataIndex: 'tel',
        },
        {
          title: '医院',
          dataIndex: 'hospital',
        },
        {
          title: '科室',
          dataIndex: 'department',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      memberColumn2: [
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机',
          dataIndex: 'tel',
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      logColumn: [
        {
          title: '记录人',
          dataIndex: 'createdByName',
        },
        {
          title: '记录时间',
          dataIndex: 'createdTime',
        },
        {
          title: '截图&视频&总结',
          scopedSlots: { customRender: 'link' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      logForm: {
        video: [],
        cover: [],
      },
      //上传视频
      fileId: '', // 文件id
      vFile: {}, // 视频文件File对象
      vIsFileExist: false, // File对象是否存在
      vUploader: {}, // 腾讯云上传的初始化对象
      signature: '',
      logType: 0,
      logAddType: 0,
      meetIds: [],
      ExcelMember: false,
      changeFile: '',
      listTel: '',
      listName: '',
      columnsList: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serialNumber' },
          width: '7%'
        }, {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: '10%'
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          key: 'tel',
        },
        {
          title: '身份',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: { customRender: 'type' },
          width: '10%'
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          key: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          key: 'hospitalOffices',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          key: 'jobTitle',
          width: '10%'
        },
        {
          title: '导入时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          scopedSlots: { customRender: 'createdTime' },
        }, {
          title: '操作',
          width: '10%',
          scopedSlots: { customRender: 'action' },
        },],
      dataLists: [],
      listPageNo: 1,
      memberId: null,
      consultationId: null,
      listpageSize: 10,
      listCount: 0,
      conventionId: null,
      testcertStatus:null,

      addPersonVisile: false,
      personnelListForm: {
        type: '1001',
        province: undefined,
        hospitalName: undefined,
        school: undefined,
        certStatus: "UNCERTIFIED",
        registerChannel: "后台添加",
      },
      personnelListRules: {
        tel: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      sexList: sexDate(),
      educationList: educationList(),
      City: City,
      jobTitleList: [],
      provincePtions: [],

      hosNamePageNo: 1,
      hosName: '',
      hospitalNameList: [], //医院列表
      certStatus: [
        {
          value: 'UNCERTIFIED',
          name: '未认证'
        }, {
          value: 'IN_REVIEW',
          name: '审核中'
        },
        {
          value: 'CERTIFIED',
          name: '已认证'
        }
      ],
      schoolNamePageNo: 1,
      schoolName: '',
      schoolListData: [], //学校列表

      hospitalOfficesList: [],

      addPersonDisabled: true,//初始状态下默认为不能编辑状态
      selectType:"",
      searchVal: "",
      search2Val:"",
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "咨询会议管理")
    const user_info = window.localStorage.getItem("user_info");
    this.addForm.byId = JSON.parse(user_info).id
    this.getHospitalOfficesList()
    this.getJobTitleList(this.addForm.type, 1)
    this.$store.dispatch('setManageHeaderTitle', "注册会员管理")
  },
  computed: {
    //  vuex-名单对话框数据
    listBox() {
      return this.$store.state.listDialogBox
    },
    listBoxIsShow() {
      return this.$store.state.listDialogBox.isShow
    },
  },

  mounted() {
    this.getLogData(1, '', 0)
    this.getPCH()
    this.getHospitalOfficesList()
    this.getJobTitleList(this.personnelListForm.type)
    this.rules = this.rules1
    this.getPCH()
  },

  methods: {
    //search事件
    onseleSearch(value) {
      this.searchVal = value  //暂存区
      this.onseleChange(this.searchVal)
      this.$set(this.addForm, 'liveValue', value)
    },
    //change事件
    async onseleChange(value){
      // 根据id去查找链接并赋值
      const info = await getZoomMeet2(1,value,"")
      if (info.code === 0 && info.data.rows[0].meetUrl) {
        this.$set(this.addForm, 'meetUrl', info.data.rows[0].meetUrl)
      }else{
        this.$set(this.addForm, 'meetUrl', "")
      }
    },
    //blur事件
    onBlur(value){
      // this.addForm.liveValue = value
      //console.log("清空了",value)
      // this.onseleChange()
      this.$set(this.addForm, 'liveValue', value)
    },
    async getMeetId(pageNum,meetId,selectType) {
      const info = await getZoomMeet2(pageNum,meetId,this.selectType)
      if (info.code === 0) {
        info.data.rows.forEach((value, index) => {
          if (value.status === 'ENABLE') {
            this.meetIds.push(value)
          }
        })
      }
    },
    onsele2Search(value) {
      this.search2Val = value  //暂存区
      this.onsele2Change(this.search2Val)
      this.$set(this.editForm, 'liveValue', value)
      //console.log("明明是输入事件啊",this.searchVal)  //失焦给我清空了value怎么办
    },
    //change事件
    async onsele2Change(value){
      // 根据id去查找链接并赋值
      const info = await getZoomMeet2(1,value,"")
      console.log("怎么不清空",info.data.rows[0])
      if (info.code === 0 && info.data.rows[0].meetUrl) {
        this.$set(this.editForm, 'meetUrl', info.data.rows[0].meetUrl)
      }else if(info.code === 0 && !info.data.rows[0].meetUrl){
        // this.$set(this.editForm, 'meetUrl', "")
        this.editForm.meetUrl = ''
      }
    },
    //blur事件
    on2Blur(value){
      // this.addForm.liveValue = value
      //console.log("清空了",value)
      // this.onsele2Change()
      this.$set(this.editForm, 'liveValue', value)
    },



    async addOk() {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          const form = this.addForm
          let data = []
          // var beginTime = ''
          // var endTime = ''
          // if(form.time){
          //   beginTime = moment(form.time[0]._d).format('YYYY-MM-DD HH:mm:ss')
          //   endTime = moment(form.time[1]._d).format('YYYY-MM-DD HH:mm:ss')
          // }
          data = {
            title: form.title,
            timeDisplay: form.timeDisplay,
            beginTime: form.time ? moment(form.time[0]._d).format('YYYY-MM-DD HH:mm:ss'):"",
            endTime: form.time? moment(form.time[1]._d).format('YYYY-MM-DD HH:mm:ss'):"",
            // beginTime: beginTime,
            // endTime: endTime,
            type: form.type,
            liveType: form.liveType,
            liveValue: form.liveValue,
            isFullOpen: form.isFullOpen,
            byId: form.byId,
            meetUrl:form.meetUrl,
            meetPassword: form.meetPassword,
          }
          console.log("数字",data)

          // switch (form.liveType) {
          //   case 'ZOOM':
          //     data = {
          //       title: form.title,
          //       timeDisplay: form.timeDisplay,
          //       beginTime: moment(form.time[0]._d).format('YYYY-MM-DD HH:mm:ss'),
          //       endTime: moment(form.time[1]._d).format('YYYY-MM-DD HH:mm:ss'),
          //       type: form.type,
          //       liveType: form.liveType,
          //       liveValue: form.liveValue,
          //       isFullOpen: form.isFullOpen,
          //       byId: form.byId
          //     }
          //     break;
          //   case 'other':
          //     data = {
          //       title: form.title,
          //       timeDisplay: form.timeDisplay,
          //       beginTime: moment(form.time[0]._d).format('YYYY-MM-DD HH:mm:ss'),
          //       endTime: moment(form.time[1]._d).format('YYYY-MM-DD HH:mm:ss'),
          //       type: form.type,
          //       liveType: form.liveType,
          //       isFullOpen: form.isFullOpen,
          //       byId: form.byId
          //     }
          //     break;
          // }




          const add = await postConsultationMeet(data)
          if (add.code === 0) {
            await this.getLogData(this.pagination.current, this.type, this.searchType)
            this.getPerList()
            const user_info = window.localStorage.getItem("user_info");
            this.$message.success('新增成功')
            this.addForm = {
              byId: JSON.parse(user_info).id,
              isFullOpen: 0,
              meetUrl:""
            }
            this.meetIds = []
            this.addShow = false
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    // 人员按钮（传meetId）
    async memberBtn(item, id) {
      this.zhuangtai = false
      this.memberId = id
      // this.getMemberLog(id)
      // this.scopeShow=true
      this.listPageNo = 1
      await this.get_columnsList(id)
      this.ExcelMember = true

      this.registerMembers.meetId = item.liveValue
      // console.log(this.registerMembers.meetId)
      // this.PostRegisterMember(this.memberId)
      this.Excel.consultationId = this.memberId
      // this.getList()
    },

    // 选择按钮(传memberid)
    amendBtnTable(id) {
      this.listBox.memberId = id  //人员id
      console.log(id)
      //打开对话框 -- ListPackaging
      this.listBox.isShow = true
      this.registerMembers.memberId = this.listBox.memberId
      this.PostRegisterMember(id, this.memberId)   //为什么命名memberId不是meetId
      this.Excel.consultationId = this.memberId   //转换     // 1.1向导出功能Export的接口PostExcel传递consultationId参数
      // // console.log("是四个数字吗"+this.meetId)
    },


    // 添加的接口
    async PostRegisterMember(memberId, meetId) {
      const response = await PostRegisterMember(memberId, meetId)
      if (response.code === 0) {
        //刷新列表
        await this.get_columnsList(this.memberId)
        this.$message.success("添加成功~")
        this.getList()
      } else {
        var Selected = document.querySelector('#Selected')
        // Selected.innerHTML = "已选择"
        this.$message.warning(response.message)
      }
    },


    // 导出功能
    async Export(name, tel, consultationId) {
      // this.get_columnsList()
      var name = this.listName; var tel = this.listTel; var consultationId = this.Excel.consultationId;
      // console.log("球球"+name+tel+consultationId)
      const response = await PostExcel(name, tel, consultationId)
    },

    async getList() {
      //  if (this.zhuangtai == false) {
      //    console.log("停止",this.zhuangtai)
      //   return false;
      //  }else{
      this.spinning = true
      let form = this.searchForm
      let data = {
        name: form.name,
        tel: form.number,
        // certStatus: form.certStatus,
        pageNo: this.pageNo,
        // type: this.selectId[0]
        pageSize:10,
        consultationId:this.Excel.consultationId
      }
      var pageSize = 20;
      const response = await getMembersList(data)
      // const response = await getMembersList(this.Excel.consultationId, form.name, form.number,this.pageNo,pageSize)
      if (response.code === 0) {
        console.log(response)
        this.tableList = response.data
        this.total = response.count
      } else {
        this.$message.warning(response.message)
      }
      this.spinning = false

    },
    //获取省份城市医院
    getPCH() {
      let province = []
      let hospitalName = []
      this.City.forEach((item, index) => {
        let city = []
        province.push({
          value: item.name,
          label: item.name,
          children: city
        })
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          })
        })
        province[index].children = city
      })
      this.hospitaloPtions = province
      this.schoolPtions = province
    },
    selectStatus(value) {
      this.pageNo = 1
      this.selectId[0] = value
      this.getList()
    },
    enterBtn() {
      this.getList()
    },
    // 要按照条件查询了 if输入框有东西就执行getlist如果没有东西就不执行
    selectBtn() {
      var input = document.querySelector('#input').value
      if (input == '' && this.searchForm.number == '') {
        this.$message.info("请输入姓名和手机号~")
        return;
      } else {
        // this.zhuangtai = true
        // console.log("开始",this.zhuangtai)
        this.getList()
        // 标记
      }
    },
    restBtn() {
      let form = this.searchForm
      form.number = ''
      form.name = ''
      this.getList()
    },
    addBtn() {
      this.drawerTitle = '新增'
      this.addVisible = true
    },
    resetConfirmTable(id) {
      let userId = [id]
      this.restPass(userId)
    },
    resetConfirm() {
      const userId = this.user_id
      if (userId !== '' && userId !== null && userId !== undefined) {
        this.restPass(userId)
      } else {
        this.$message.warning("请选择需要重置密码的列表~")
      }
    },
    async restPass(userId) {
      const response = await restPassWorld(userId)
      if (response.code === 0) {
        this.$message.success("重置密码成功，密码重置后为 111111")
        //清空
        this.selectedRowKeys = []
        this.user_id = []
        //刷新列表
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    lookBtn() {

    },
    amendBtn() {
      let listId = this.user_id
      if (listId.length === 1) {
        this.drawerTitle = '修改'
        //获取单个列表内容
        // this.getListOne(listId[0])
        // this.addVisible = true
        this.listBox.memberId = listId[0]
        //打开对话框 -- ListPackaging
        this.listBox.isShow = true
      } else {
        this.$message.warning("请选择想要修改的一个列表~")
      }
    },
    page1Change(pageNo) {
      this.pageNo = pageNo
      console.log(pageNo)
      this.getList()
    },
    selectNumberType() {
      this.$set(this.addForm, 'jobTitle', undefined)
      //其他医务从业者--医务从业者不需要选择省份城市
      if (this.addForm.type !== '1006' || this.addForm.type !== '1007') {
        this.getJobTitleList(this.addForm.type, 1)
      }
      //选择身份时切换rules校验
      switch (this.addForm.type) {
        case '1001': {
          this.rules = this.rules1
          break;
        }
        case '1002': {
          this.rules = this.rules1
          break;
        }
        case '1003': {
          this.rules = this.rules1
          break;
        }
        case '1004': {
          this.rules = this.rules1
          break;
        }
        case '1005': {
          this.rules = this.rules2
          break;
        }
        case '1006': {
          this.rules = this.rules3
          break;
        }
        case '1007': {
          this.rules = this.rules3
          break;
        }
        default: {
          break;
        }
      }
    },

    onSelectChange(selectedRowKeys) {
      //选择的列表的id
      this.user_id = selectedRowKeys
      this.selectedRowKeys = selectedRowKeys
    },
    provinceChange(value) {
      if (this.addForm.type === '1001' ||
        this.addForm.type === '1002' ||
        this.addForm.type === '1003' ||
        this.addForm.type === '1004'
      ) {
        let data = {
          province: value[0],
          city: value[1],
          pagenum: this.hospitalNameList_pageNo
        }
        this.getHosNameList(data)
      } else if (this.addForm.type === '1005') {
        let data = {
          province: value[0],
          city: value[1]
        }
        this.getschoolList(data)
      }
    },
    //获取职称列表
    async getJobTitleList(identify, pageNum, title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify, pageNum, title)
      if (response.code === 0) {
        this.jobTitleList = response.data.row
      }
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data)
      if (response.code === 0) {
        this.hospitalNameList = response.data.rows
      }
    },
    //获取学院列表
    async getschoolList(data) {
      const response = await getSchool(data)
      if (response.code === 0) {
        this.schoolListData = response.data.rows
      }
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if (response.code === 0) {
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item, index) => {
          let twoOff = []
          oneOff.push({
            value: item.title,
            label: item.title,
            children: twoOff
          })
          item.zoffices.forEach((lists) => {
            twoOff.push({
              value: lists.title,
              label: lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },
    jobTitleChange(value) {
      console.log(value);
    },
    officeChange(value) {
      console.log(value);
    },
    hospitalSeleChange() {

    },
    addClose() {
      this.addVisible = false
      //清空
      this.selectedRowKeys = []
      this.user_id = []
      this.$refs.addRuleForm.resetFields()
      let form = this.addForm
      form.birthday = undefined
      form.sex = undefined
      form.email = ''
      form.certStatus = '未认证'
      form.registerChannel = '后台添加'
    },
    onSubmit() {
      this.$refs.addRuleForm.validate(valid => {
        if (valid) {
          let form = this.addForm
          let data = {};
          if (this.drawerTitle === '新增') {
            switch (form.type) {
              case '1001': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0] + '-' + form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1002': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0] + '-' + form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1003': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0] + '-' + form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1004': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "hospitalName": form.hospitalName,
                  "hospitalOffices": form.hospitalOffices[0] + '-' + form.hospitalOffices[1],
                  "jobTitle": form.jobTitle,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1005': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "school": form.school,
                  "education": form.education,
                  "specialty": form.specialty,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1006': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "workUnit": form.workUnit,
                  "post": form.post,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              case '1007': {
                data = {
                  "type": form.type,
                  "name": form.name,
                  "tel": form.tel,
                  "workUnit": form.workUnit,
                  "post": form.post,

                  "province": form.province[0],
                  "city": form.province[1],

                  "sex": form.sex,
                  "email": form.email,
                  "birthday": form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : '',
                  "certStatus": form.certStatus,
                  "registerChannel": form.registerChannel,
                  "nvqUrl": '',
                }
                break;
              }
              default: {
                break;
              }
            }
            //新增
            this.postList(data)
          }

          this.addVisible = false
          //清空
          this.selectedRowKeys = []
          this.user_id = []
          this.$refs.addRuleForm.resetFields()

          form.birthday = undefined
          form.sex = undefined
          form.email = ''
          form.certStatus = '未认证'
          form.registerChannel = '后台添加'
        } else {
          return false;
        }
      });
    },
    //  新增
    async postList(data) {
      const response = await postMember(data)
      if (response.code === 0) {
        this.$message.success("新增成功~")
        //  刷新列表
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    //  修改
    async putList(id, data) {
      const response = await putMember(id, data)
      if (response.code === 0) {
        this.$message.success("修改成功~")
        //  刷新列表
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },



    //获取省份城市医院
    getPCH() {
      let province = []
      let hospitalName = []
      this.City.forEach((item, index) => {
        let city = []
        province.push({
          value: item.name,
          label: item.name,
          children: city
        })
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          })
        })
        province[index].children = city
      })
      this.provincePtions = province
    },
    addPersonOnSubmit() {  ////人员名单新增-确认对话框
      this.$refs.PersonnelListRef.validate(async valid => {
        if (valid) {
          let form = this.personnelListForm
          if (form.id) {
            const response = await insMeetMember(form.id, this.memberId)
            if (response.code === 0) {
              this.$message.success("添加成功~")
              //  关闭对话框并刷新列表
              await this.get_columnsList(this.memberId)
              this.addPersonClose()
            } else {
              this.$message.warning(response.message)
            }
          } else {
            this.$message.warning("该手机号未注册，请先注册~")
          }
        } else {
          return false;
        }
      });
    },
    async saveEditPer() {
      this.$refs.PersonnelListRef.validate(async valid => {
        if (valid) {
          let form = this.personnelListForm
          let data = {};
          switch (form.type) {
            case '1001': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1002': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1003': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1004': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices ? form.hospitalOffices[0] + '-' + form.hospitalOffices[1] : null,
                "jobTitle": form.jobTitle,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1005': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "school": form.school,
                "education": form.education,
                "specialty": form.specialty,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1006': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "workUnit": form.workUnit,
                "post": form.post,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            case '1007': {
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "workUnit": form.workUnit,
                "post": form.post,

                "province": form.province ? form.province[0] : null,
                "city": form.province ? form.province[1] : null,

                // "doctorNum": form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                "birthday": form.birthday,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl": '',
              }
              break;
            }
            default: {
              break;
            }
          }
          // 判断form中包不包含id
          if (form.id) {
            // console.log('修改')
            await this.putList(form.id, data)
          } else {
            // console.log('新增')
            await this.postList(data)
            //新增成功后再获取用户信息，并赋值
            await this.echo(data.tel)
          }
          this.addPersonDisabled = true
        } else {
          return false;
        }
      });
    },
    //  新增注册会员信息
    async postList(data) {
      const response = await postMember(data)
      if (response.code === 0) {
        this.$message.success("新增成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    //  修改注册会员信息
    async putList(id, data) {
      const response = await putMember(id, data)
      if (response.code === 0) {
        this.$message.success("修改成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    editPerBtn() {
      this.addPersonDisabled = false
      // document.getElementById('name').style.color = "black"
      if (this.personnelListForm.province) {
        // this.provinceChange(this.personnelListForm.province)

        //更改省份城市后清空医院或者学校
        let form = this.personnelListForm
        if (form.type === '1001' || form.type === '1002' || form.type === '1003' || form.type === '1004') {
          let data = {
            province: form.province[0],
            city: form.province[1],
            pagenum: this.hosNamePageNo,
            name: this.hosName
          }
          this.getHosNameList(data)
        } else if (form.type === '1005') {
          let data = {
            province: form.province[0],
            city: form.province[1]
          }
          this.getschoolList(data)
        }
      }
    },
    //滚动条监听
    popupScrollSchool(e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.schoolNamePageNo++
        let value = this.personnelListForm.province
        let data = {
          province: value[0],
          city: value[1],
          pagenum: this.schoolNamePageNo,
          name: this.schoolName
        }
        this.getschoolList(data)
      }
    },
    //鼠标搜索
    selectSchool(value) {
      //首先清空数组，否则会数据重复
      this.schoolListData = []
      this.schoolName = value
      this.schoolNamePageNo = 1
      let values = this.personnelListForm.province
      let data = {
        province: values[0],
        city: values[1],
        pagenum: this.schoolNamePageNo,
        name: value
      }
      this.getschoolList(data)
    },
    //获取学院列表
    async getschoolList(data) {
      const response = await getSchool(data)
      if (response.code === 0) {
        let list = response.data.rows
        for (let i = 0; i < list.length; i++) {
          this.schoolListData.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollHos(e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hosNamePageNo++
        let value = this.personnelListForm.province
        let data = {
          province: value[0],
          city: value[1],
          pagenum: this.hosNamePageNo,
          name: this.hosName
        }
        this.getHosNameList(data)
      }
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data)
      if (response.code === 0) {
        let list = response.data.rows
        for (let i = 0; i < list.length; i++) {
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //鼠标搜索
    selectHos(value) {
      //首先清空数组，否则会数据重复
      this.hospitalNameList = []
      this.hosName = value
      this.hosNamePageNo = 1
      let values = this.personnelListForm.province
      let data = {
        province: values[0],
        city: values[1],
        pagenum: this.hosNamePageNo,
        name: value
      }
      this.getHosNameList(data)
    },
    nameChange(e) {
      if (e.target.value !== '未完善') {
        document.getElementById('name').style.color = ''
      } else {
        document.getElementById('name').style.color = 'red'
      }
    },
    async echo(value) {
      // let data = {
      //   consultationId:this.Excel.consultationId,
      //   tel: value,
      // }
      // console.log("看看",data)
      const response = await getMembersList(value,this.Excel.consultationId)
      if (response.code === 0) {
        if (response.count) {
          //  获取用户信息并赋值（没赋值完啊）
          let perDate = response.data[0]
          // 设置数据
          perDate.province = perDate.province ? [perDate.province, perDate.city] : []
          perDate.hospitalOffices = perDate.hospitalOffices ? perDate.hospitalOffices.split('-') : null
          if (perDate.name !== '未完善') {
            document.getElementById('name').style.color = ''
          } else {
            document.getElementById('name').style.color = 'red'
          }
          this.personnelListForm = perDate
          this.btnStatus = true;
          this.$message.warning("该用户已注册，无需新增，请移步查询人员添加此用户")
          // console.log( this.personnelListForm )
        }
         else {
          this.$message.warning("该用户不存在请添加~")
          this.btnStatus = false;
          this.personnelListForm = {
            type: '1006',
            tel: this.personnelListForm.tel,
            province: undefined,
            hospitalName: undefined,
            school: undefined,
            certStatus: "UNCERTIFIED",
            registerChannel: "后台添加",
            name: '未完善'
          }
          document.getElementById('name').style.color = "red"
        }
      } else {
        this.$message.warning(response.message)
      }
    },
    selectNumberType(value) {
      this.personnelListForm.type = value
      this.$set(this.personnelListForm, 'jobTitle', undefined)
      // this.personnelListForm.jobTitle = undefined
      //其他医务从业者--医务从业者不需要选择省份城市
      if (value !== '1006' || value !== '1007') {
        this.getJobTitleList(value)
      }
      if (this.personnelListForm.province) {
        this.provinceChange(this.personnelListForm.province)
      }
    },
    //获取职称列表
    async getJobTitleList(identify, title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify, 1, title)
      if (response.code === 0) {
        this.jobTitleList = response.data.row
      }
    },
    provinceChange(value) {
      this.hosNamePageNo = 1
      this.schoolNamePageNo = 1
      //更改省份城市后清空医院或者学校
      let form = this.personnelListForm
      if (!this.addPersonDisabled) { //当表单在能编辑状态下，选择省份后清空医院或者学校
        form.hospitalName = undefined
        this.hospitalNameList = []
        this.schoolListData = []
        form.school = undefined
      }
      if (form.type === '1001' || form.type === '1002' || form.type === '1003' || form.type === '1004') {
        let data = {
          province: value[0],
          city: value[1],
          pagenum: this.hosNamePageNo,
          name: this.hosName
        }
        this.getHosNameList(data)
      } else if (form.type === '1005') {
        let data = {
          province: value[0],
          city: value[1],
          pagenum: this.schoolNamePageNo,
          name: this.schoolName
        }
        this.getschoolList(data)
      }
    },
    onSearch(value) {
      if (value.length === 11) {
        this.echo(value)
      } else {
        this.$message.warning("请规范输入手机号~")
      }
    },
    addPersonClose() { //人员名单新增-关闭对话框
      this.addPersonVisile = false
      this.btnStatus = false
      // 切换身份时清空数据
      this.personnelListForm = {
        type: "1001",
        province: undefined,
        hospitalName: undefined,
        school: undefined,
        certStatus: "UNCERTIFIED",
        registerChannel: "后台添加",
      }
      this.hospitalNameList = [];
      this.schoolListData = [];
      this.hosNamePageNo = 1;
      this.schoolNamePageNo = 1;
      this.hosName = '';
      this.schoolName = '';
    },
    addPerson() { //人员名单新增
      this.addPersonDisabled = true
      this.addPersonVisile = true
    },
    inquireBtn() {
      this.listPageNo = 1
      this.listpageSize = 10
      this.get_columnsList(this.memberId)
    },
    async DownloadTemplate() {
      let data = {
        ExelName: '人员名单模板',
      }
      await meetMemberTemplate(data)
    },
    async get_columnsList(id) {
      let data = {
        page_no: this.listPageNo,
        page_size: this.listpageSize,
        tel: this.listTel,
        name: this.listName
      }
      const response = await get_staff_list(id, data)
      if (response.code === 0) {
        this.dataLists = response.data
        this.attendData = [];
        for (var i = 0; i < this.dataLists.length; i++) {
          this.attendData = this.dataLists[i].tel
          this.dataSum[i] = this.dataLists[i].tel
        }
        // console.log("dataSum", this.dataSum)
        this.listCount = response.count
      } else {
        this.$message.warning(response.message)
      }
    },
    templateChange(pagNo, pageSize) {
      this.listPageNo = pagNo
      this.listpageSize = pageSize
      this.get_columnsList(this.memberId)
    },
    templateChangeSize(current, size) {
      this.listPageNo = current
      this.listpageSize = size
      this.get_columnsList(this.memberId)
    },
    async confirmDelList(id) {
      const response = await delMeetPer(id)
      if (response.code === 0) {
        //刷新列表
        await this.get_columnsList(this.memberId)
        this.$message.success("操作成功")
        this.getList()
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },
    excelClose() {
      this.ExcelMember = false;
      this.listPageNo = 1;
      this.listpageSize = 10;
      this.listTel = '';
      this.listName = '';
      this.changeFile = '';
      this.formData = {}
      this.searchForm.number = '';
      this.searchForm.name = '';
      // this.form.name = '';
      // this.form.number = '请输入';
      this.getList()
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if (response.code === 0) {
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item, index) => {
          let twoOff = []
          oneOff.push({
            value: item.title,
            label: item.title,
            children: twoOff
          })
          item.zoffices.forEach((lists) => {
            twoOff.push({
              value: lists.title,
              label: lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },
    async changeFileElsx() {
      let inputDOM = this.$refs.uploadFile_inputFile.files[0];
      let formData = new FormData()
      formData.append('file', inputDOM)
      this.changeFile = inputDOM.name
      this.formData = formData
    },
    async importAddFile() {
      if (this.formData) {
        const response = await postUploadFileMeet(this.memberId, this.formData)
        if (response.code === 0) {
          //上传成功后将value制空防止上传一样名称文件时不触发change
          document.getElementById('uploadFile').value = null;
          //刷新列表
          await this.get_columnsList(this.memberId)
          this.$message.success(response.message)
        } else {
          this.$message.warning(response.message)
        }
      } else {
        this.$message.warning("请先选择文件~")
      }

    },
    async getLogData(pageNum, type, isOpen, title) {
      const info = await getConsultationMeet(pageNum, type, isOpen, title)
      if (info.code === 0) {
        await this.getPerList()
        this.pagination.total = info.data.count
        this.InfoTable = info.data.rows
        this.InfoTable.forEach((value, index) => {
          this.leaderOperateList.forEach((ids, idIndex) => {
            if (value.createdById === ids.id) {
              value.createdByName = ids.userName
            }
          })
        })
      }
    },
    onaddClose(){
      this.addShow = false
      this.meetIds = []
      this.addForm = {
        byId: null,
        isFullOpen: 0,
        meetUrl:""
      }
    },
    radioChange(e){
      // console.log('radio checked', e.target.value);
      // this.addForm.liveValue = undefined
      this.$set(this.addForm, 'liveValue', undefined)
      this.meetIds = []
      this.$set(this.addForm, 'meetUrl', "")
      this.selectType = e.target.value
      this.getMeetId()
    },
    radioChange2(e){
      // console.log('radio checked', e.target.value);
      // this.editForm.liveValue = undefined
      this.$set(this.editForm, 'liveValue', undefined)
      this.meetIds = []
      this.$set(this.editForm, 'meetUrl', "")
      this.selectType = e.target.value
      this.getMeetId()
    },

    //查看人员
    async getMemberLog(consultationId, pageNum, pageSize, name, tel) {
      const info = await getMeetMember(consultationId, pageNum, pageSize, name, tel)
      if (info.code === 0) {
        this.memberPage.total = info.count
        this.memberData = info.data
      }
    },
    memberPageChange(current, pageSize) {
      this.memberPage.current = current
      this.memberPage.pageSize = pageSize
      this.getMemberLog(this.memberId, current, pageSize, this.memberLogName)
    },

    //获取公司人员列表
    async getPerList(departmentById) {
      const response = await getCommunictionList(1, 9999, 1, '', departmentById)
      if (response.code === 0) {
        this.leaderOperateList = response.data.rows
      }
    },

    async getMeetLog(consultationId, id) {
      const info = await getMeetLog(consultationId, id)
      if (info.code === 0) {
        this.logData = info.data
        this.logData.forEach((value, index) => {
          value.createdById = Number(value.createdById)
          this.leaderOperateList.forEach((ids, idIndex) => {
            if (value.createdById === ids.id) {
              value['createdByName'] = ids.userName
            }
          })

        })
      }
    },
    searchTypes(value) {
      this.getLogData(1, this.type, value)
    },
    searchBtn() {
      this.getLogData(1, this.type, this.searchType, this.searchName)
    },
    searchChange() {
      this.getLogData(1, '', 0)
      this.searchType = 0
      this.searchName = ''
      this.type = ''
    },

    pageChange() {
      this.getLogData(this.pagination.current, this.type, this.searchType)
    },
    addBtn() {
      this.getMeetId()
      this.addShow = true
    },
    openWitch(checked) {
      if (checked === false) {
        this.addForm.isFullOpen = 0
      } else {
        this.addForm.isFullOpen = 1
      }
    },
    openEdit(open) {
      this.editForm.isFullOpen = open
    },
    async editBtn(item) {
      if (item.liveType == 'TENCENT'){
        this.selectType = "TENCENT"
      }else if(item.liveType == 'ZOOM'){
        this.selectType = "ZOOM"
      }else{
        this.selectType = ""  // other的情况
      }
      this.getMeetId()
      this.editForm = JSON.parse(JSON.stringify(item))
      this.editShow = true
    },

    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const form=this.editForm
          let data=[]
          data={
                title:form.title,
                timeDisplay:form.timeDisplay,
                beginTime:moment(form.beginTime).format('YYYY-MM-DD HH:mm:ss'),
                endTime:moment(form.endTime).format('YYYY-MM-DD HH:mm:ss'),
                type:form.type,
                liveType:form.liveType,
                liveValue:form.liveValue,
                isFullOpen:form.isFullOpen,
                byId:form.createdById,
                id:form.id,
                meetUrl:form.meetUrl,
                meetPassword: form.meetPassword,
              }
          // switch (form.liveType){
          //   case 'ZOOM':
          //     data={
          //       title:form.title,
          //       timeDisplay:form.timeDisplay,
          //       beginTime:moment(form.beginTime).format('YYYY-MM-DD HH:mm:ss'),
          //       endTime:moment(form.endTime).format('YYYY-MM-DD HH:mm:ss'),
          //       type:form.type,
          //       liveType:form.liveType,
          //       liveValue:form.liveValue,
          //       isFullOpen:form.isFullOpen,
          //       byId:form.createdById,
          //       id:form.id
          //     }
          //     break;
          //   case 'other':
          //     data={
          //       title:form.title,
          //       timeDisplay:form.timeDisplay,
          //       beginTime:moment(form.beginTime).format('YYYY-MM-DD HH:mm:ss'),
          //       endTime:moment(form.endTime).format('YYYY-MM-DD HH:mm:ss'),
          //       type:form.type,
          //       liveType:form.liveType,
          //       isFullOpen:form.isFullOpen,
          //       byId:form.createdById,
          //       id:form.id
          //     }
          //     break;
          // }
          const edit = await putConsultationMeet(data)
          if (edit.code===0){
            await this.getLogData(this.pagination.current,this.type,this.searchType)
            this.getPerList()
            this.$message.success('修改成功')
            this.meetIds = []
            this.editForm={}
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },

    async getSearchMember(name,tel){
      const info = await getMeetMemberTel(name,tel)
      if (info.code===0){
        this.telData=info.data
        if (info.data.length===0){
          this.$message.warn('查询为空')
        }
      }else {
        this.$message.warning(info.message)
      }
    },

    searchTel(){
        this.getSearchMember(this.memberName,this.memberTel)
    },
    searchLogTel(){
      if (this.memberLogName===''){
        this.getMemberLog(this.memberId,1,'','',this.memberLogTel)
      }else if (this.memberLogTel===''){
        this.getMemberLog(this.memberId,1,'',this.memberLogName)
      }else {
        this.getMemberLog(this.memberId,1,'',this.memberLogName,this.memberLogTel)
      }

    },
     logBtn(id,open){

      if (open===1){
        this.logType=1
      }else {
        this.logType=0
      }
      this.logId=id
      this.getMeetLog(id)
      this.logShow=true

    },

    async logAddOk(){
      const user_info = window.localStorage.getItem("user_info");
      const form=this.logForm
      const data={
        createdById:JSON.parse(user_info).id,
        content:form.content,
        video:form.video,
        cover:form.cover,
        consultationId:this.logId
      }
      const add = await postMeetLog(data)
      if (add.code===0){
        this.getMeetLog(this.logId)
        this.$message.success('提交成功')
        this.logForm={
          video:[],
          cover:[]
        }
      }
    },
    logEditBtn(row){
      this.logAddType=1
      this.logType=0
      this.logForm=row
    },
    async logEditOk(){
      const form=this.logForm
      const data={
        createdById:form.createdById,
        content:form.content,
        video:form.video,
        cover:form.cover,
        id:form.id
      }
      const add = await putMeetLog(data)
      if (add.code===0){
        this.getMeetLog(this.logId)
        this.$message.success('修改成功')
        this.logForm={
          video:[],
          cover:[]
        }
        this.logAddType=0
      }
    },
    logLook(row){
      this.logLookForm=JSON.parse(JSON.stringify(row))
      this.logFormShow=true
    },

    async addPeople(memberId){
      const data={
        consultationId:this.memberId,
        memberId:memberId
      }
      const add = await postMeetMember(data)
      if (add.code===0){
        this.getMemberLog(this.memberId,1,this.memberPage.pageSize,)
        this.$message.success('操作成功')
      }
    },

    searchOk(e){
      switch (e.target.value){
        case 'METTING':
          this.getLogData(1,'METTING',this.searchType, this.searchName)
          // console.log("类型",this.searchType)
          this.type='METTING'
              break;
        case 'CONAULTATION':
          this.getLogData(1,'CONAULTATION',this.searchType, this.searchName)
          this.type='CONAULTATION'
              break;
        case '':
          this.getLogData(1,'',this.searchType, this.searchName)
          this.type=''
              break;
      }
    },


    async uploadImg() {
      let inputDOM = this.$refs.addImg.files[0];
      if(inputDOM.size <2086666.24){
        const image =await update(inputDOM)
        if (image.code === 0){
          this.logForm.cover.push(image.data.url)
          document.getElementById('addImg').value = null;
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
      else {
        this.$message.warning('仅上传2M内图片')
      }
    },
    delImg(index) {
      this.logForm.cover.splice(index, 1)
    },
    delVideo(index) {
      this.logForm.video.splice(index, 1)
    },

    //获取签名
    async content_surface_plot_getSignature() {
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
      }
    },
    //获取视频url地址
    async content_video_url(videoId) {
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        this.logForm.video.push(response.data.mediaUrl)
        // this.$set(this.logForm,'url',response.data.mediaUrl)
      }else {
        this.$message.warning(response.message)
      }
    },
    // 上传腾讯云
    uploadVideoFile_toyun(){
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前文中所述的获取上传签名的函数
      })
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调
        //视频上传后获取到的视频fileId
        this.logForm.file_id = res.fileId
        this.content_video_url(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    //上传视频
    async uploadVideo(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息
          document.getElementById('addVideo').value = null;
          await this.content_surface_plot_getSignature()
          await this.uploadVideoFile_toyun()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
    },

    async delTitle(id){
      const Info =await delConsultationMeet(id)
      if (Info.code === 0){
        await this.getLogData(this.pagination.current,this.type,this.searchType)
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败')
      }
    },
     async delMember(id){
       const Info =await delMeetMember(id)
       if (Info.code === 0){
         await this.getMemberLog(this.memberId,this.memberPage.current,this.memberPage.pageSize,)
         this.$message.success('操作成功')
       }else {
         this.$message.error('操作失败')
       }
     },
    async delLog(id){
      const Info =await delMeetLog(id)
      if (Info.code === 0){
        await this.getMeetLog(this.logId)
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败')
      }
    },
    cancel(){
      this.$message.warning('取消操作')
    },
    async openTitle(id) {
      const Info =await openMeetLog(id)
      if (Info.code === 0){
        await this.getLogData(this.pagination.current,this.type,this.searchType)
        this.$message.success('开启成功')
      }else {
        this.$message.error(Info.message)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.consultationMeets {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .consultationMeets_header {
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .consultationMeets_table {
    margin-top: 10px;
    padding: 15px;
    background: white;
  }
}

.editForm {
  ::v-deep .ant-calendar-picker {
    min-width: 45% !important;
  }
}

::v-deep .ant-modal-body {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px;
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
}

// 尝试加粗
.ant-table-thead th {
  font-weight: 1000;
}

.registerMember {
  width: 98%;
  display: flex;

  .registerMember-left {
    width: 10%;
  }

  .registerMember-right {
    width: 100%;

    .registerMember-right-top {
      width: 100%;
      height: 65px;

      .registerMember-right-top-input {
        min-width: 25%;
        height: 50px;
        line-height: 50px;
      }
    }

    .registerMember-right-table {
      width: 100%;

      .registerMember-right-table-top-btn {
        width: 100%;
        height: 50px;
        margin-left: 20px;
        margin-top: 15px;
      }

      .registerMember-right-table-top-table {
        width: 100%;
        // margin-left: 15px;
      }
    }

    .pageClass {
      width: 100%;
      text-align: right;
      float: right;
      margin-top: 15px;
    }
  }
}

.icon {
  font-size: 8px;
  margin-right: 5px;
}
</style>

